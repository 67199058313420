import { render, staticRenderFns } from "./PluginManager.vue?vue&type=template&id=bbfcdff6&scoped=true"
import script from "./PluginManager.vue?vue&type=script&lang=js"
export * from "./PluginManager.vue?vue&type=script&lang=js"
import style0 from "./PluginManager.vue?vue&type=style&index=0&id=bbfcdff6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbfcdff6",
  null
  
)

export default component.exports